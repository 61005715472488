<template>
  <div :class="['panel border-right border-left']">
    <div class="w-100">
      <div class="header d-none d-md-block" id="header">
        <b-row>
          <b-col cols="7" class="pr-0">
            <b-dropdown
              id="dropdown-brand"
              variant="light"
              right
              class="py-1 w-100"
            >
              <template #button-content>
                <div
                  class="d-flex justify-content-between align-items-center w-100 text-chatroom"
                >
                  <div class="ml-1 mr-4 color-primary">
                    {{ selectedChatRoom.name }}
                  </div>
                  <div class="ml-4 color-primary">
                    {{ selectedChatRoom.totalCount }}
                  </div>
                </div>
              </template>
              <b-dropdown-item
                v-for="item in chatRoom"
                :key="`chat-room-${item.modeId}`"
                @click="onChatModeChange(item.modeId)"
                :class="{ active: `${chatModeId}` === `${item.modeId}` }"
              >
                <div class="d-flex justify-content-between w-100">
                  <span>{{ item.name }}</span>
                  <span>{{ item.totalCount }}</span>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col cols
            ><b-dropdown
              id="dropdown-brand"
              variant="light"
              right
              class="py-1 w-100"
            >
              <template #button-content>
                <div class="d-flex align-items-center w-100">
                  <img
                    height="16"
                    :src="selectedChannelName.imageUrl"
                    :alt="selectedChannelName.name"
                    class="mr-2 rounded"
                    v-if="selectedChannelName.id != 99"
                  />
                  <div>
                    {{ selectedChannelName.name }}
                  </div>
                </div>
              </template>
              <b-dropdown-item
                v-for="(channel, bindex) in socialChannelList"
                :key="`social-channel-${bindex}`"
                @click="onChannelChange(channel)"
                :class="{ active: `${socialChannelId}` === `${channel.id}` }"
              >
                <img
                  height="16"
                  :src="channel.imageUrl"
                  :alt="channel.name"
                  class="mr-2 rounded"
                  v-if="channel.id != 99"
                />
                <div>
                  {{ channel.name }}
                </div>
              </b-dropdown-item>
            </b-dropdown></b-col
          >
        </b-row>
      </div>
      <div
        :class="[
          'filter',
          { 'show-select-user': displayCheckboxSelectUserChat }
        ]"
        id="filter"
      >
        <div class="border-top border-bottom">
          <b-col
            cols="12"
            xl="12"
            class="d-flex justify-content-between align-items-center"
          >
            <b-input-group>
              <b-form-input
                @keypress="handleInputSearch"
                placeholder="Search Chat"
                v-model="textSearchInput"
                class="position-relative rounded-pill"
              >
              </b-form-input>
              <span class="icon-search">
                <font-awesome-icon :icon="['fas', 'search']"
              /></span>
            </b-input-group>
            <b-dropdown
              size="md"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              right
              button-class="p-0"
            >
              <template #button-content>
                <span class="icon-filter color-primary p-0">
                  <font-awesome-icon :icon="['fas', 'sort-amount-down']"
                /></span>
              </template>
              <b-dropdown-item href="#" @click="handleSearchSortBy(1)"
                >Newes to Oldest</b-dropdown-item
              >
              <b-dropdown-item href="#" @click="handleSearchSortBy(0)"
                >Oldest to Newes</b-dropdown-item
              >
            </b-dropdown>
            <chat-option-menu
              :chatModeId="chatModeId"
              @handleShowCheckboxSelectUserChat="showCheckboxSelectUserChat"
            ></chat-option-menu>
          </b-col>
          <div
            class="d-flex align-items-center select-user-all"
            v-if="displayCheckboxSelectUserChat"
          >
            <b-form-checkbox
              :id="`checkbox-user-all`"
              :name="`checkbox_user-all`"
              class="checkbox-select-chat"
              v-model="selectedUserChatAll"
              :value="true"
              :unchecked-value="false"
              :disabled="conversation.chatList.length == 0"
              @change="handleSelectedAllAgent"
            >
              <p>
                Select All
              </p>
            </b-form-checkbox>
            {{ selectedChatList.length || 0 }} Selected
            <span class="ml-2 text-black-50">(Max 20)</span>
            <b-button
              variant="link"
              class="btn-cancel-select-chat ml-auto"
              @click="showCheckboxSelectUserChat(false)"
              >Cancel</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="card-chat mb-sm-3 mb-md-0 contacts_card-chat">
      <div v-if="!isError" class="card-chat-body contacts_body">
        <div
          v-for="(item, indexKey) in conversation.chatList"
          :key="`conversation-${item.id}`"
          :class="[
            'contacts py-0',
            { active: item.id === conversationId },
            { disabled: displayCheckboxSelectUserChat }
          ]"
          @click="
            () => {
              if (!displayCheckboxSelectUserChat) {
                $emit('select-chat', item);
                setReadConversation(item.id);
              }
            }
          "
        >
          <li class="">
            <div
              :class="[
                'd-flex bd-highlight',
                {
                  'checkbox-show': displayCheckboxSelectUserChat
                }
              ]"
            >
              <div
                class="d-flex justify-content-center align-items-center wrap-unread-message"
              >
                <template>
                  <div
                    v-if="
                      item.lastMessage.adminRead == 0 &&
                        item.lastMessage.userType == 'user'
                    "
                    class="mx-auto unread-message font-weight-bold"
                  ></div>
                  <div v-else class="read-message">
                    -
                  </div>
                </template>
              </div>
              <div
                class="img_cont d-flex justify-content-center align-items-center"
                @mouseover="item.isDisplayCheckbox = true"
                @mouseleave="item.isDisplayCheckbox = false"
                @click.stop=""
              >
                <template>
                  <div
                    v-if="
                      item.lastMessage &&
                        item.imageUrl &&
                        item.imageUrl.trim() != ''
                    "
                    class="rounded-circle user_img"
                  >
                    <div
                      class="rounded-circle user_img_display
                      "
                      v-bind:style="{
                        backgroundImage: 'url(' + item.imageUrl + ')'
                      }"
                    ></div>
                  </div>
                  <font-awesome-icon
                    v-else
                    :icon="['fas', 'user']"
                    class="rounded-circle user_img p-1"
                  />
                </template>
                <b-form-checkbox
                  :id="`checkbox-${indexKey}`"
                  :name="`checkbox_${indexKey}`"
                  class="checkbox-select-chat"
                  v-model="selectedChatList"
                  :value="{
                    id: item.id,
                    name: item.name,
                    mode: item.mode,
                    socialType: item.socialType
                  }"
                  :unchecked-value="false"
                  :disabled="
                    displayCheckboxSelectUserChat &&
                      (chatModeId == 99 || chatModeId == 96) &&
                      item.mode == 5
                  "
                  @change="$event => handleChangeSelectChat($event, item.id)"
                />
              </div>
              <div class="user_info">
                <div class="text-truncate d-flex">
                  {{
                    item.displayName ? item.displayName : item.name || "Unknow"
                  }}
                  <b-badge
                    v-if="
                      (chatModeId == 99 || chatModeId == 96) &&
                        chatModeLabel[item.mode]
                    "
                    pill
                    class="badge-mode"
                    :variant="
                      item.mode == 3
                        ? 'outline-main'
                        : item.mode == 4
                        ? 'yellow'
                        : item.mode == 5
                        ? 'green'
                        : 'main'
                    "
                    >{{
                      item.mode != 3
                        ? chatModeLabel[item.mode].name
                        : item.mode == 3 &&
                          item.lastAdminId != adminProfile.externalId
                        ? `Assign`
                        : chatModeLabel[item.mode].name
                    }}</b-badge
                  >
                </div>
                <p
                  v-if="item.lastMessage"
                  :class="[
                    'mb-0',
                    item.lastMessage.adminRead == 0 &&
                    item.lastMessage.userType == 'user'
                      ? 'unread-conversation'
                      : ''
                  ]"
                >
                  <font-awesome-icon
                    v-if="item.lastMessage.userType == 'admin'"
                    :icon="['fas', 'reply']"
                  />
                  {{
                    `${
                      item.lastMessage.messageType == 8 ||
                      item.lastMessage.messageType == 9
                        ? "ส่งไฟล์"
                        : item.lastMessage.message || " "
                    }`
                  }}
                </p>
                <p v-else class="mb-0" style="visibility: hidden">-</p>
                <div class="tag-social">
                  <img
                    v-if="
                      $store.getters.getChatSocialList.length &&
                        $store.getters.getChatSocialList.findIndex(
                          t => t.id == item.socialType
                        ) >= 0
                    "
                    :src="
                      $store.getters.getChatSocialList[
                        $store.getters.getChatSocialList.findIndex(
                          t => t.id == item.socialType
                        )
                      ].imageUrl
                    "
                    alt=""
                    class="mt-1"
                  />
                  <span
                    v-if="
                      $store.getters.getChatSocialList.length &&
                        $store.getters.getChatSocialList.findIndex(
                          t => t.id == item.socialType
                        ) >= 0
                    "
                    class="mb-0"
                    >{{
                      $store.getters.getChatSocialList[
                        $store.getters.getChatSocialList.findIndex(
                          t => t.id == item.socialType
                        )
                      ].name
                    }}</span
                  >
                </div>
              </div>
              <div
                v-if="item.lastMessage"
                :class="[
                  'update-time-inbox ml-auto text-right',
                  item.lastMessage.adminRead == 0 &&
                  item.lastMessage.userType == 'user'
                    ? 'text-black'
                    : ''
                ]"
              >
                <ChatDateTime
                  v-if="item.lastMessage"
                  :date="item.lastMessage.createdTime"
                />
                <!-- <div>
                  <div
                    v-if="
                      item.lastMessage.adminRead == 0 &&
                        item.lastMessage.userType == 'user'
                    "
                    class="ml-auto unread-message font-weight-bold"
                  ></div>
                  <div v-else style="visibility: hidden">-</div>
                </div> -->
                <div
                  class="text-right text-con-mode mt-auto"
                  v-if="
                    item.lastAdminId &&
                      item.lastAdminFirstname &&
                      item.lastAdminLastname
                  "
                >
                  <div
                    v-if="item.lastAdminImageUrl"
                    class="rounded-circle user_img_display img-admin-size mb-1"
                    v-bind:style="{
                      backgroundImage: 'url(' + item.lastAdminImageUrl + ')'
                    }"
                    :id="`tooltip-admin-${indexKey}`"
                  ></div>
                  <div
                    v-else
                    class="rounded-circle img-admin img-admin-size mb-1"
                    :id="`tooltip-admin-${indexKey}`"
                  >
                    <span>{{ formatName(item.lastAdminFirstname) }}</span>
                  </div>
                  <b-tooltip
                    :target="`tooltip-admin-${indexKey}`"
                    triggers="hover"
                    variant="light"
                    placement="bottomright"
                  >
                    {{ `${item.lastAdminFirstname} ${item.lastAdminLastname}` }}
                  </b-tooltip>
                  <!-- <span v-if="item.mode == 1">
                    <font-awesome-icon :icon="['fas', 'user']" />
                    <span v-if="item.adminName">
                      {{ item.adminName }}
                    </span>
                    <span v-else class="ml-1">Admin Mode</span>
                  </span> -->
                  <!-- <span v-else>
                    <font-awesome-icon :icon="['fas', 'robot']" />
                    Bot Mode
                  </span> -->
                </div>
              </div>
            </div>
            <!-- <pre>{{ item }}</pre>  -->
          </li>
        </div>
        <infinite-loading
          spinner="spiral"
          @infinite="scrollMethod"
          ref="infiniteLoadingChatConversation"
        >
          <div
            slot="spinner"
            class="spinner-border text-secondary p-2 mt-2"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
        <div class="loading-prepare" v-if="isLoading && !isLoadingInfinite">
          <div class="spinner-border text-secondary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div
          v-if="conversation.chatList.length < 1 && !isLoading"
          class="reload-chat"
        >
          <div class="text-center">
            <p>ไม่พบรายการแชท</p>
          </div>
        </div>
      </div>
      <div v-if="isError" class="reload-chat">
        <div class="text-center">
          <p>Network error!!</p>
          <button class="btn btn-primary btn-sm" @click="reloadPage">
            Reload
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatDateTime from "@/components/chat/ChatDateTime";
import { mapGetters } from "vuex";
import ChatOptionMenu from "./ChatOptionMenu.vue";
export default {
  name: "ChatList",
  components: {
    ChatDateTime,
    ChatOptionMenu
  },
  props: {
    conversationId: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      tabIndex: 0,
      selectedMode: 0,
      countUnread: {
        admin: 0,
        all: 0
      },
      isError: false,
      isLoading: false,
      isLoadingInfinite: false,
      chatList: {},
      conversation: {
        chatList: [],
        total: 0
      },
      textSearchInput: "",
      limit: 14,
      startingAfter: "",
      sortBy: 1,
      filterCurrentAdmin: false,
      selectedChatList: [],
      selectedUserChatAll: false
    };
  },
  methods: {
    async prepareData() {
      this.isError = false;
      await this.getChatList(false);
    },
    reloadPage() {
      location.reload();
    },
    formatName(val) {
      if (val) {
        val = val
          .trim()
          .substring(0, 1)
          .toUpperCase();
      }
      return val;
    },
    onChannelChange(channelSelected) {
      if (channelSelected.id !== this.socialChannelId) {
        this.$store.dispatch("setChatSocialChannel", channelSelected.id);
      }
    },
    async getChatList(scrollFlag, $state) {
      // if (!this.$cookies.isKey("d-order-session-token")) {
      //   window.location.href = `${this.$backofficeUrl}/login`;
      // }
      this.isLoading = true;
      if (this.conversation.chatList.length > 0) {
        this.startingAfter = this.conversation.chatList[
          this.conversation.chatList.length - 1
        ].id;
      }
      await fetch(
        `${this.$chatBaseUrl}/chatroom?start=${this.startingAfter ||
          ""}&length=${this.limit || "0"}&adminMode=${this.selectedMode ||
          "99"}&searchName=${
          this.textSearchInput.trim() === "" ? "" : this.textSearchInput
        }&brandId=${this.$route.query.brandID || "0"}&sortBy=${
          this.sortBy
        }&isCurrentAdmin=${this.filterCurrentAdmin || "false"}&socialType=${this
          .socialChannelId || "99"}`,
        {
          method: "GET",
          headers: new Headers(this.$headersChat)
        }
      )
        .then(response => {
          return response.json();
        })
        .then(result => {
          this.isLoading = false;
          this.isLoadingInfinite = false;
          this.startingAfter = "";
          if (result.result) {
            if (scrollFlag) {
              if (result.detail.length == 0) {
                if ($state) $state.complete();
              } else {
                // this.conversation.chatList = [
                //   ...this.conversation.chatList,
                //   ...result.detail,
                // ];

                // check already have id in list -> dont push
                for (let i = 0; i < result.detail.length; i++) {
                  if (
                    this.conversation.chatList.filter(
                      chat => chat.userId == result.detail[i].userId
                    ).length == 0
                  ) {
                    this.conversation.chatList.push({
                      ...result.detail[i],
                      isDisplayCheckbox: false
                    });
                  }
                }
                if ($state) $state.loaded();
              }
            } else {
              let detailMap = result.detail.map(chat => ({
                ...chat,
                isDisplayCheckbox: false
              }));
              this.conversation.chatList =
                result.detail === null ? [] : detailMap;
              if ($state) $state.loaded();
            }

            // // set new admin name
            // if (this.newAdminChatName) {
            //   for (let i = 0; i < this.conversation.chatList.length; i++) {
            //     if (
            //       this.conversation.chatList[i].id ==
            //       this.newAdminChatName.chatID
            //     ) {
            //       this.conversation.chatList[
            //         i
            //       ].adminName = this.newAdminChatName.adminName;
            //     }
            //   }
            // }
          } else {
            this.isError = true;
          }
          console.log(this.isError);
        })
        .catch(error => {
          console.log("error", error);
          this.isError = true;
          this.isLoading = false;
        });
    },
    scrollMethod($state) {
      setTimeout(async () => {
        this.startingAfter =
          this.conversation.chatList.length > 0
            ? this.conversation.chatList[this.conversation.chatList.length - 1]
                .conversationId
            : "";
        this.isLoadingInfinite = true;
        this.getChatList(true, $state);
      }, 500);
    },
    handleSearchSortBy(val) {
      this.sortBy = val;
      this.handleSearch();
    },
    onChatModeChange(modeId) {
      if (modeId !== this.chatModeId) {
        this.showCheckboxSelectUserChat(false);
        this.$store.dispatch("setChatMode", modeId);
      }
    },
    handleHandleSelectType(val) {
      this.selectedMode = val;
      if (this.$refs.infiniteLoadingChatConversation) {
        this.$refs.infiniteLoadingChatConversation.stateChanger.reset();
      }

      this.conversation = {
        chatList: [],
        total: 0
      };
      this.getChatList(false);
    },
    handleInputSearch(e) {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        this.handleSearch();
      }
    },
    async handleSearch() {
      if (this.$refs.infiniteLoadingChatConversation) {
        this.$refs.infiniteLoadingChatConversation.stateChanger.reset();
      }
      this.conversation = {
        chatList: [],
        total: 0
      };
      await this.getChatList(false);
    },
    handleChangeCurrentAdmin() {
      if (this.$refs.infiniteLoadingChatConversation) {
        this.$refs.infiniteLoadingChatConversation.stateChanger.reset();
      }
      this.conversation = {
        chatList: [],
        total: 0
      };
      this.getChatList(false);
    },
    getChatDetail(lastMessageId) {
      fetch(`${this.$chatBaseUrl}/message/${lastMessageId}`, {
        method: "GET",
        headers: new Headers(this.$headersChat)
      })
        .then(response => response.json())
        .then(async result => {
          if (result.result == 1) {
            this.conversation.chatList.forEach((item, i) => {
              if (lastMessageId === item.lastMessageId) {
                this.$set(this.conversation.chatList, i, {
                  ...this.conversation.chatList[i],
                  lastMessage: result.detail
                });
              }
            });
          }
        })
        .catch(error => {
          console.log("error", error);
          this.isError = true;
          this.isLoading = false;
        });
    },
    reloadDetailList() {
      this.handleSearch();
    },
    setReadConversation(conversationId) {
      this.conversation.chatList.forEach((con, i) => {
        if (con.id === conversationId) {
          this.$set(this.conversation.chatList, i, {
            ...this.conversation.chatList[i],
            lastMessage: { ...con.lastMessage, adminRead: 1 }
          });
          // console.log(con);
          this.$store.commit("setImgUrlCurrentChat", con.imageUrl);
          this.$store.commit("setNameCurrentChat", con.name);
        }
      });
    },
    showCheckboxSelectUserChat(val) {
      if (val == false) {
        this.selectedUserChatAll = [];
        this.selectedChatList = [];
      }
      this.$store.dispatch("setDisplayCheckboxSelectUserChat", val);
    },
    async handleSelectedAllAgent(value) {
      this.selectedChatList = [];
      let isModeAll = this.chatModeId == 99 || this.chatModeId == 96;
      if (value) {
        let number = 1;
        await this.conversation.chatList.forEach(item => {
          if (number <= 20) {
            if ((isModeAll && item.mode != 5) || !isModeAll) {
              this.selectedChatList.push({
                id: item.id,
                name: item.name,
                mode: item.mode,
                socialType: item.socialType
              });
              number++;
            }
          }
        });
      }
    },
    handleChangeSelectChat(value, id) {
      let fIndex = value.findIndex(x => x.id === id);
      if (fIndex < 0) this.selectedUserChatAll = false;
    },
    selectChatAgent(item) {
      this.$emit("select-chat", item);
      this.setReadConversation(item.id);
    }
  },
  computed: {
    newAdminChatName: function() {
      return this.$store.state.newAdminChatName;
    },
    newChatItem: function() {
      return this.$store.state.newChatItem;
    },
    selectedChatRoom() {
      let filtered = this.chatRoom.filter(ele => ele.modeId == this.chatModeId);
      if (filtered.length) {
        return filtered[0];
      } else {
        return {
          id: null,
          modeId: 0,
          name: "",
          sort: 0,
          totalCount: 0
        };
      }
    },
    selectedChannelName() {
      let filtered = this.socialChannelList.filter(
        ele => ele.id == this.socialChannelId
      );
      if (filtered.length) {
        return filtered[0];
      } else {
        return { id: 0, name: "All Channel", imageUrl: "" };
      }
    },
    ...mapGetters({
      chatRoom: "getChatModeCount",
      chatModeId: "getChatMode",
      socialChannelId: "getChatSocialChannel",
      socialChannelList: "getChatSocialChannelList",
      displayCheckboxSelectUserChat: "getDisplayCheckboxSelectUserChat",
      chatModeLabel: "getChatModeLabel",
      adminProfile: "getChatAdminProfile"
    })
  },
  async created() {
    await this.prepareData();
    // this.$store.commit("setNewAdminChatName", {
    //   chatID: "62cfaae7263e7b0d816eb219",
    //   adminName: "ok",
    // });
    if (this.$store.state.tempData.dcrm.chatRoomId) {
      let filtered = this.conversation.chatList.filter(
        ele => ele.id == this.$store.state.tempData.dcrm.chatRoomId
      );
      if (filtered.length) {
        this.selectChatAgent(filtered[0]);
        this.$store.dispatch("setTempDCRM", {
          brandId: "",
          chatRoomId: ""
        });
      }
    }
  },
  watch: {
    "$store.state.chatModeID": function(newVal) {
      // console.log("chatModeID", newVal);
      this.handleHandleSelectType(newVal || 99);
    },
    "$store.state.chatSocialChannelID": function() {
      this.handleHandleSelectType(this.chatModeId || 99);
    },
    "$store.state.isLoadChatConversationList": function() {
      if (this.$store.state.isLoadChatConversationList) {
        // let isHaveCon = false;
        let conUpdateList = this.$store.getters.getChatConversationUpdate;
        this.conversation.chatList.forEach((item, i) => {
          conUpdateList.forEach(con => {
            if (con.chatroomId === item.id) {
              // isHaveCon = true;
              this.$set(this.conversation.chatList, i, {
                ...this.conversation.chatList[i],
                lastMessage: con
              });
              const tempCon = this.conversation.chatList[i];
              this.conversation.chatList.splice(i, 1);
              this.conversation.chatList.unshift(tempCon);
            }
          });
        });
        let temCon = this.$store.getters.getChatConversationUpdate;
        temCon.shift();
        this.$store.commit("UPDATE_CHAT_CONVERSATION_UPDATE", temCon);
      }
    },
    "$store.state.newAdminChatName": function() {
      // this.getChatList(false);
      // set new admin name
      for (let i = 0; i < this.conversation.chatList.length; i++) {
        if (this.conversation.chatList[i].id == this.newAdminChatName.chatID) {
          this.conversation.chatList[
            i
          ].adminName = this.newAdminChatName.adminName;
        }
      }
    },
    "$store.state.newChatItem": function() {
      if (this.selectedMode) {
        // tab require admin
        if (this.$store.getters.getNewChatItem.isToAdmin) {
          this.conversation.chatList = [
            this.$store.getters.getNewChatItem.chatroom
          ].concat(this.conversation.chatList);
        } else {
          if (this.$store.getters.getNewChatItem.isFirstMessage || false) {
            this.handleSearch();
          } else {
            if ([3, 4, 5].includes(this.selectedMode)) {
              let newChatId = this.$store.getters.getNewChatItem.chatroom.id;
              this.conversation.chatList = this.conversation.chatList.filter(
                function(item) {
                  return item.id != newChatId;
                }
              );
            }
          }
        }
      } else {
        if (this.$store.getters.getNewChatItem.isToAdmin) {
          let newChatId = this.$store.getters.getNewChatItem.chatroom.id;

          this.conversation.chatList = this.conversation.chatList.filter(
            function(item) {
              return item.id != newChatId;
            }
          );
          this.conversation.chatList = [
            this.$store.getters.getNewChatItem.chatroom
          ].concat(this.conversation.chatList);
        } else {
          for (let i = 0; i < this.conversation.chatList.length; i++) {
            if (
              this.conversation.chatList[i].id ==
              this.$store.getters.getNewChatItem.chatroomId
            ) {
              this.conversation.chatList.splice(i, 1);
              this.conversation.chatList = [
                this.conversation.chatList[i]
              ].concat(this.conversation.chatList);
            }
          }
        }
      }
    },
    "$store.state.lastChatItem": async function(newVal) {
      let objIndex = await this.conversation.chatList.findIndex(
        item => item.id === newVal.chatroomId
      );
      if (newVal.userType == "system" && newVal.mode == 5) {
        if (objIndex >= 0) {
          if (this.conversation.chatList[objIndex].mode != 5) {
            this.conversation.chatList[objIndex] = newVal.roomDetail;
          }
        } else {
          this.conversation.chatList.push({ ...newVal.roomDetail });
        }
      } else if (newVal.userType == "admin" && newVal.mode == 3) {
        if (objIndex >= 0) {
          this.conversation.chatList[objIndex] = newVal.roomDetail;
        }
      } else if (newVal.userType == "user" && newVal.mode == 2) {
        if (objIndex >= 0) {
          this.conversation.chatList[objIndex] = newVal.roomDetail;
        }
      } else if (
        (newVal.userType == "system" && newVal.message == "Assign to ") ||
        (newVal.userType == "system" &&
          newVal.message == "Move to Pending" &&
          newVal.mode == 4)
      ) {
        if (this.adminProfile.userType == "SUPERADMIN") {
          if (
            this.conversation.chatList[objIndex].adminName !==
            newVal.toAdminName
          ) {
            this.conversation.chatList[objIndex] = newVal.roomDetail;
          }
        } else if (newVal.toAdminExternalId == this.adminProfile.externalId) {
          if (objIndex >= 0) {
            this.conversation.chatList[objIndex] = newVal.roomDetail;
          } else {
            this.conversation.chatList.push({ ...newVal.roomDetail });
          }
        } else {
          if (objIndex >= 0) {
            this.conversation.chatList.splice(objIndex, 1);
            if (newVal.chatroomId == this.conversationId)
              this.$emit("exit-chat-selected");
          }
        }
      }
    },
    selectedChatList: function(newVal) {
      this.$store.dispatch("setSelectedUserChatList", newVal);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .nav-pills .nav-link {
  border-radius: 0px !important;
  color: #8f20c6;
}

::v-deep .nav-pills .nav-link.active {
  color: #fff !important;
  background: #8f20c6;
}

::v-deep .input-group-text {
  background: #fff !important;
}

::v-deep .dropdown-menu.show {
  padding: 0;
}

::v-deep .btn:not(:disabled):not(.disabled) {
  padding: 0;
}

::v-deep #dropdown-brand {
  .dropdown-toggle {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dropdown-toggle::after {
    margin-left: 10px !important;
  }
}
::v-deep .filter.show-select-user {
  > div {
    padding-bottom: 5px;
  }
}
::v-deep .filter {
  .dropdown-toggle::after {
    display: none;
  }
  > div {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.header {
  padding: 10px 15px;
}

.text-chatroom {
  min-width: 130px;
}

.img-admin {
  background-color: #aaa5e4;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(113, 96, 196, 0.27);
  font-size: 12px;
}

.img-admin-size {
  height: 20px !important;
  width: 20px !important;
  padding: 0 !important;
}

.icon-search {
  position: absolute;
  top: 7px;
  right: 15px;
  color: #768192;
}

.icon-filter {
  cursor: pointer;
  margin-left: 10px;
  svg {
    font-size: 18px;
  }
}

.panel {
  height: 100%;
  margin: 0;
}

.card-chat {
  height: calc(100% - 67px - 67px);
  background-color: #f6f6f6;
  border-radius: 0;
}

.contacts_body {
  height: 100%;
  padding: 0 !important;
  overflow-y: auto;
  white-space: nowrap;
}

.contacts_body::-webkit-scrollbar {
  width: 0.35em;
}

.contacts_body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(177, 175, 175, 0.3);
}

.contacts_body::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  outline: 1px solid #c1c1c1;
}

.card-chat-header {
  border-radius: 15px 15px 0 0 !important;
  border-bottom: 0 !important;
}

.card-chat-footer {
  border-radius: 0 0 15px 15px !important;
  border-top: 0 !important;
}

.search {
  border-radius: 15px 0 0 15px !important;
  background-color: #f7f7f7;
  border: 1 !important;
  border-right: none;
  border-color: #cccccc;
  color: #707070 !important;
}

.search:focus {
  box-shadow: #707070 !important;
  outline: 1px !important;
}

.search_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: #f7f7f7 !important;
  border: 1 !important;
  border-left: none;
  border-color: #cccccc;
  color: #707070 !important;
  cursor: pointer;
}

.contacts {
  list-style: none;
  padding: 0;
  cursor: pointer;
  border-bottom: 1px solid #e4e4e4;
  background-color: #ffffffbb;
}

.contacts li {
  width: 100% !important;
  padding: 5px 10px;
  // margin-top: 10px !important;
  // margin-bottom: 10px !important;
}

.active {
  background-color: #eeeeee;
}

.user_img {
  height: 36px;
  width: 36px;
  border: 1.5px solid #f5f6fa;
  background-color: #fff;
}

.user_img_display {
  width: 100%;
  padding-bottom: 100.5%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ebedef;
}

.img_cont {
  position: relative;
  height: 36px;
  width: 36px;
  margin: auto 0;
  text-align: center;
  margin-left: 5px;
}

.online_icon {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #4cd137;
  border-radius: 50%;
  bottom: 0.2em;
  right: 0.4em;
  border: 1.5px solid white;
}

.offline {
  background-color: #c23616 !important;
}

.user_info {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  overflow: hidden;
  padding-right: 10px;
}

.user_info span {
  font-size: 14px;
  color: rgb(0, 0, 0);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.user_info p {
  font-size: 12px;
  color: #9c9c9c;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 14px;
}

.unread-conversation {
  color: #000 !important;
  font-size: 14px !important;
}

.update-time-inbox {
  position: relative;
  font-size: 13px;
  width: auto;
  color: #9c9c9c;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.unread-message {
  top: 0;
  right: -8px;
  background-color: #832abf !important;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  // padding: 5px 0;
  font-size: 14px;
}

.text-black {
  color: #000 !important;
}

.tag-social {
  display: flex;
  flex-flow: row;
  margin-top: 3px;
}

.tag-social img {
  height: 16px;
  width: auto;
}

.tag-social span {
  font-size: 12px;
  color: #9c9c9c;
  margin: auto 0 auto 5px;
}

.reload-chat,
.loading-prepare {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.spinner-border {
  width: 20px;
  height: 20px;
}

.text-con-mode {
  font-size: 12px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.text-con-mode span {
  margin: auto 0;
  color: #fff;
  font-weight: bold;
}

.text-con-mode img {
  border-radius: 50%;
  height: 22px;
}

// select {
//   border: none;
// }

::v-deep .nav-tabs .nav-link,
.nav-tabs .nav-item.show .nav-link {
  color: #707070 !important;
}

::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: 0px solid #832abf !important;
  color: #832abf !important;
  background-color: #ffffff !important;
  border-bottom: 4px solid #832abf !important;
}

// ::v-deep .nav-link:hover {
//   border: 0px solid #a15ccf !important;
//   border-bottom: 4px solid #9450c2 !important;
// }

::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #832abf;
  background-color: #832abf;
}

.badge-primary {
  color: #fff;
  background-color: #832abf;
}
.select-user-all {
  width: 100% !important;
  font-size: 12px;
  padding: 5px 10px 0 10px;
}
::v-deep .checkbox-select-chat {
  margin-top: 2px;
  margin-left: 0.5rem;
  display: none;
  cursor: pointer;
  .custom-control-label {
    cursor: pointer;
  }
  .custom-control-input:disabled + .custom-control-label {
    cursor: default !important;
  }
  p {
    text-decoration: underline !important;
    margin-right: 10px;
    color: #832abf !important;
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 1px;
  }
}
.checkbox-show {
  .checkbox-select-chat {
    display: block;
    position: absolute;
  }
  .user_img {
    opacity: 0.4;
    background-color: white;
  }
}
.btn-cancel-select-chat {
  text-decoration: underline !important;
  color: #832abf !important;
  font-size: 12px;
}
.checkbox-show {
  .checkbox-select-chat {
    display: block;
  }
}
.select-user-all {
  .checkbox-select-chat {
    display: block;
  }
}

.wrap-unread-message {
  width: 10px;
  .read-message {
    visibility: hidden;
    width: 10px;
  }
}
@media (max-width: 767.98px) {
  .panel {
    position: relative;
    // top: 149px;
    top: 40px;
    height: calc(100dvh - 40px);
    margin: 0;
  }

  .mobile-panel {
    top: 0px !important;
  }

  .card-chat {
    // height: calc(100% - 120px);
    height: calc(100dvh - 40px - 67px);
    background-color: #f6f6f6;
    border-radius: 0;
    // position: fixed;
    width: 100%;
    // top: 149px;
    // left: 0;
  }

  .contacts_body {
    position: relative;
    height: calc(100%);
    // height: calc(100% - 104px - 50px);
    width: 100%;
    top: 0;
    left: 0;
  }
}
</style>
