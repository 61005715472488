<template>
  <div class="h-100">
    <ChatSideBarSetReply />
    <div
      v-if="isShowChat && !(isNoConversation && conversationId == '')"
      class="d-flex h-100"
    >
      <div v-show="activePanel == 1 || isShowBoth" :class="['panel-list-chat']">
        <div v-if="isNoConversation" class="h-100 border-right">
          <div class="panel-wellcome-chat">
            <div class="text-center">
              <font-awesome-icon :icon="['fas', 'comment-alt']" class="" />
              <p class="mb-0">ไม่พบรายการแชท</p>
            </div>
          </div>
        </div>
        <ChatList
          v-else
          ref="ChatList"
          @select-chat="handleSelectChat"
          @set-show-title="val => $emit('set-show-title', val)"
          :conversationId="conversationId"
          @handle-no-list-chat="val => (isNoConversation = val)"
          @exit-chat-selected="exitChatSelected"
        />
      </div>
      <div
        v-if="activePanel == 2 || activePanel == 3 || isShowBoth"
        :class="['panel-box-chat']"
      >
        <ChatBox
          v-if="activePanel == 2 && conversationId != ''"
          :meProfile="meProfile"
          :otherProfile="otherProfile"
          :isFromList="isFromList"
          :isShowBoth="isShowBoth"
          :socialType="socialType"
          @show-list-chat="
            () => {
              setActivePanel(1);
              conversationId = '';
            }
          "
          @set-show-title="val => $emit('set-show-title', val)"
          @reload-list="$refs.ChatList.getChatList(false)"
          @reload-detail-list="val => $refs.ChatList.reloadDetailList(val)"
          @set-read-conversation="
            val => $refs.ChatList.setReadConversation(val)
          "
          @handle-no-list-chat="val => (isNoConversation = val)"
          @set-selected-user-chat="val => (selectedUserChatInfo = val)"
          :formatIdConversation="conversationId"
          :userId="selectedUserId"
          :channelId="selectedChannelId"
          :class="[isShowBoth ? 'w-50' : 'w-100']"
          :conversationInfo="conversationInfo"
          :userChatInfo="selectedUserChatInfo"
        />

        <div
          v-if="(activePanel == 2 || isShowBoth) && conversationId == ''"
          class="panel-wellcome-chat"
        >
          <div class="text-center">
            <font-awesome-icon :icon="['fas', 'comment-alt']" class="" />
            <p class="mb-0">ยินดีตอนรับเข้าสู่แชท</p>
          </div>
        </div>
        <ChatOrderPanel
          v-if="
            (activePanel == 3 || isShowBoth) &&
              selectedUserChatInfo.externalId != '' &&
              selectedUserChatInfo.externalId.length > 0
          "
          class="w-50 border-left"
          :userChatInfo="selectedUserChatInfo"
          :socialType="socialType"
          :chatRoomId="conversationId"
          :isShowBoth="isShowBoth"
          @get-chatlist="$refs.ChatList.reloadDetailList()"
        />
      </div>
    </div>
    <div v-if="isNoConversation && conversationId == ''" class="h-100">
      <div class="panel-wellcome-chat">
        <div class="text-center">
          <font-awesome-icon :icon="['fas', 'comment-alt']" class="" />
          <p class="mb-0">ไม่พบรายการแชท</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatList from "@/components/chat/ChatList";
import ChatBox from "@/components/chat/chatBox/ChatBox";
import ChatOrderPanel from "@/components/chat/chatOrder/ChatOrderPanel";
import ChatSideBarSetReply from "@/components/chat/ChatSideBarSetReply";
import { mapGetters } from "vuex";

export default {
  name: "ChatPanel",
  components: {
    ChatList,
    ChatBox,
    ChatOrderPanel,
    ChatSideBarSetReply
  },
  props: {
    isShowBoth: {
      required: false,
      type: Boolean
    },
    isStateInbox: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      isShowChat: false,
      isFromList: false,
      meProfile: {
        seller: {}
      },
      otherProfile: {
        chatId: ""
      },
      conversationId: "",
      isNoConversation: false,
      selectedUserId: "",
      selectedUserChatInfo: {
        externalId: ""
      },
      selectedChannelId: "",
      socialType: 0,
      conversationInfo: {}
    };
  },
  computed: {
    ...mapGetters({
      activePanel: "getActivePanel"
    })
  },
  methods: {
    setActivePanel(number) {
      this.$store.dispatch("setActivePanel", number);
    },
    handleSelectChat(val) {
      if (this.conversationId != val.id) {
        this.isFromList = true;
        this.setActivePanel(2);
        this.conversationId = "";
        this.selectedUserId = "";
        this.selectedChannelId = "";
        this.socialType = 0;
        this.conversationInfo = {};
        this.$store.dispatch("setAgentSelected", true);
        setTimeout(() => {
          this.conversationId = val.id;
          this.selectedUserId = val.userId;
          this.selectedChannelId = val.channelId;
          this.conversationInfo = val;
          this.socialType = val.socialType;
        }, 50);
      } else {
        this.setActivePanel(2);
      }
    },
    exitChatSelected() {
      this.setActivePanel(1);
      this.conversationId = "";
      this.selectedUserId = "";
      this.selectedChannelId = "";
      this.socialType = 0;
      this.conversationInfo = {};
      this.selectedUserChatInfo = {
        externalId: ""
      };
    },
    getSomeChatroom() {
      fetch(
        `${this.$chatBaseUrl}/chatroom/chatroom-external?userGUID=${this.$route.query.userGUID}&brandId=${this.$route.query.brandID}&SocialTypeId=${this.$route.query.socialTypeId}`,
        {
          method: "GET",
          headers: new Headers(this.$headersChat)
        }
      )
        .then(response => response.json())
        .then(async result => {
          if (result.result) {
            this.handleSelectChat(result.detail);
            this.isShowChat = true;
            this.isFromList = false;
          }
        })
        .catch(error => {
          console.log("error", error);
        });
    }
  },
  created() {
    if (
      this.$route.query.isChatBox == "true" &&
      this.$route.query.userGUID &&
      this.$route.query.brandID &&
      this.$route.query.socialTypeId
    ) {
      this.getSomeChatroom();
    } else {
      this.isShowChat = true;
      this.isFromList = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-wellcome-chat {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  width: 100%;
}
.panel-wellcome-chat svg {
  width: 5rem;
  height: 5rem;
}

.panel-list-chat {
  width: 25%;
}
.panel-box-chat {
  width: 75%;
  height: 100%;
  display: flex;
}
@media (max-width: 1440px) {
  .panel-list-chat {
    width: 30%;
  }
  .panel-box-chat {
    width: 70%;
  }
}
@media (max-width: 1024px) {
  .panel-list-chat {
    width: 50%;
  }
  .panel-box-chat {
    width: 50%;
  }
}
@media (max-width: 767.98px) {
  .panel-list-chat {
    width: 100%;
  }
  .panel-box-chat {
    width: 100%;
    .panel-ChatOrderPanel {
      width: 100% !important;
    }
  }
}
</style>
