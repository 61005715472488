<template>
  <b-modal
    hide-footer
    hide-header
    v-model="showModal"
    size="xl"
    centered
    no-close-on-esc
    no-close-on-backdrop
  >
    <div>
      <iframe
        :src="url"
        frameborder="0"
        allowfullscreen
        class="iframe-customs"
      ></iframe>
      <div class="mt-3 d-flex w-100">
        <div class="mx-auto">
          <b-button @click="hide" variant="outline-primary">ยกเลิก</b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      url: "",
      originUrlTicket: "",
      eventsTicket: [
        "onUpdateTicketSuccess",
        "onUpdateTicketError",

        "onCreateNoteSucess",
        "onCreateNoteError",

        "onCreateTicketSuccess",
        "onCreateTicketError",

        "onCancelModal"
      ]
    };
  },
  mounted() {
    window.addEventListener("message", this.handleCloseIframe);
  },
  methods: {
    show(urlTicket) {
      this.url = urlTicket;
      let pathArray = urlTicket.split("/");
      let protocol = pathArray[0];
      let host = pathArray[2];
      let url = protocol + "//" + host;
      this.originUrlTicket = url;
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
      window.removeEventListener("mousemove", this.handleCloseIframe);
      this.$emit("reloadTicket");
    },
    handleCloseIframe(event) {
      if (event.origin === this.originUrlTicket) {
        if (event.data == "onCancelModal") {
          this.hide();
        } else if (event.data == "onUpdateTicketSuccess") {
          console.log("onUpdateTicketSuccess");
        } else if (event.data == "onUpdateTicketError") {
          console.log("onUpdateTicketError");
        } else if (event.data == "onCreateNoteSucess") {
          console.log("onCreateNoteSucess");
        } else if (event.data == "onCreateNoteError") {
          console.log("onCreateNoteError");
        } else if (event.data == "onCreateTicketSuccess") {
          this.hide();
        } else if (event.data == "onCreateTicketError") {
          console.log("onCreateTicketError");
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .btn-main-primary,
::v-deep .btn-outline-primary {
  min-width: 150px !important;
}
::v-deep .modal-body {
  padding: 0px 0 15px 0;
}
::v-deep iframe {
  width: 100%;
  height: 80vh;
}
@media (max-width: 767.98px) {
  ::v-deep .btn-main-primary,
  ::v-deep .btn-outline-primary {
    min-width: unset !important;
  }
}
</style>
