<template>
  <div class="d-none">
    <b-modal v-model="showModal" centered hide-header hide-footer>
      <div class="modal-header">
        <button type="button" aria-label="Close" class="close" @click="hide">
          ×
        </button>
      </div>
      <div class="px-md-3 pb-3">
        <UploadFile
          textFloat="นำเข้าไฟล์"
          placeholder="Please Choose File"
          format="excel"
          name="file"
          text="*Please upload only file .xlsx less than 10 MB"
          isRequired
          v-on:onFileChange="onFileChange"
          v-on:delete="deleteFile"
          :fileName="filename"
          id="uploadfile"
          v-model="form.File"
        />
      </div>
      <div class="form-inline justify-content-center mt-2">
        <b-button class="mr-2 btn-modal" @click="hide">ยกเลิก</b-button>
        <b-button class="ml-2 btn-modal" variant="main-1" @click="uploadFile"
          >ยืนยัน</b-button
        >
      </div>
    </b-modal>
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import UploadFile from "@/components/inputs/UploadFile";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";

export default {
  props: {
    base64: {
      required: false,
      type: Boolean
    }
  },
  components: {
    UploadFile,
    ModalAlert,
    ModalAlertError
  },
  data() {
    return {
      showModal: false,
      filename: "",
      form: {
        File: ""
      },
      isDisable: false,
      modalMessage: ""
    };
  },
  methods: {
    show() {
      this.deleteFile();
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    deleteFile() {
      this.form.File = null;
      this.filename = null;
      this.isDisable = true;
    },
    onFileChange(file) {
      let data = null;
      this.filename = file.name;
      this.form.file_name = file.name;
      this.isDisable = false;
      // const str =
      //   "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        data = this.base64
          ? `doc;${reader.result.split("sheet;")[1]}`
          : reader.result;
        // this.form.File = reader.result.substring( str.length, reader.result.length );
        this.form.File = data;
      };
    },
    async uploadFile() {
      this.$emit("uploadFile", this.form);
    }
  }
};
</script>

<style scoped>
.modal-header {
  border-bottom: 0 !important;
  margin: 0px;
  padding: 0px;
  padding-bottom: 0.25rem;
}
.text-msg {
  font-size: 2rem;
  color: #4a4a4a;
}
.text-text {
  color: #4a4a4a;
  margin-bottom: 0;
  font-size: 1.2rem;
}
.text-sub {
  color: #afce38;
  font-size: 1.25rem;
}
.modal-body {
  margin: auto;
  text-align: center;
  padding: 0;
}
.btn-modal {
  width: 100px;
  font-weight: bold;
}
::v-deep .btn-main-1 {
  background-color: #832abf !important;
  border-color: #832abf;
  color: #fff;
}
</style>
