<template>
  <div :class="['recent-card']">
    <template v-if="items.length > 0">
      <div class="px-3">
        <div
          v-for="item in items"
          :key="item.id"
          class="activity-card mb-2"
          :class="[{ 'is-close': item.ticket_status_id == 4 }]"
        >
          <div class="activity-icon">
            <font-awesome-icon
              :icon="getActivityIcon(item.activity_source_id)"
              class="icon"
            />
            <hr class="my-2" />
          </div>
          <div class="activity-info">
            <div class="date">
              {{ $moment(item.created_time).format("DD/MM/YYYY hh:mm:ss") }}

              <font-awesome-icon
                icon="edit"
                class="edit-icon"
                @click="showFormTicket(item.ticket_url)"
              />
            </div>
            <div class="name">{{ item.name }}</div>
            <ul class="info">
              <li v-if="item.activity_source_type">
                {{ item.activity_source_type }}
              </li>
              <li>Status: {{ item.status }}</li>
              <li v-if="item.sla_status == 1 && item.ticket_status_id === 1">
                <span class="badge badge-new badge-pill">New</span>
              </li>
            </ul>
            <div class="type">{{ item.ticket_type }}</div>
          </div>
        </div>
        <b-button
          variant="outline-primary"
          class="my-2"
          @click="toUrlTicket(ticketUrlViewAll)"
          >View All</b-button
        >
      </div>
    </template>
    <div
      v-if="!items.length"
      class="d-flex justify-content-center align-items-center w-100 mb-4"
    >
      <span class="mt-1">No Data</span>
    </div>
    <ModalTicketIframe ref="ModalTicketIframe" @reloadTicket="getList" />
  </div>
</template>

<script>
import ModalTicketIframe from "../../chatOrderProfile/modal/ModalTicketIframe.vue";

import { mapGetters } from "vuex";

export default {
  props: {
    userId: {
      required: true,
      type: String
    },
    isCustomer: {
      required: false,
      type: Boolean,
      default: false
    },
    titleClass: {
      required: false,
      type: String,
      default: "title"
    },
    userDetail: {
      required: true,
      type: [Object]
    },
    chatRoomId: {
      required: false,
      type: String
    }
  },
  components: {
    ModalTicketIframe
  },
  data() {
    return {
      items: [],
      ticketUrlViewAll: "",
      isLoading: false,
      popupCheckerInterval: null
    };
  },
  // watch: {
  //   userId: {
  //     handler(newVal) {
  //       if (newVal) this.getList();
  //     },
  //     immediate: true
  //   }
  // },
  computed: {
    ...mapGetters({
      brandId: "getBrand",
      urlCreate: "getAgentTicketCreate",
      isRegister: "getAgentTicketFlagRegister"
    })
  },
  created() {
    this.getList();
  },
  methods: {
    getActivityIcon(id) {
      if (id == 33) return ["fab", "line"];
      else if (id == 34) return "phone-alt";
      return "phone";
    },
    getList: async function() {
      let result = 0;
      this.isLoading = true;
      if (this.userDetail.user_guid) {
        let body = {
          userGUID: this.userId,
          crmUserId: this.userDetail.user_guid,
          chatRoomId: this.chatRoomId,
          brandId: this.brandId,
          telephone: this.userDetail.telephone
        };
        await this.$axios
          .post(`${this.$chatBaseUrl}/crm/Ticket`, body)
          .then(response => response.data)
          .then(async res => {
            if (res.result) {
              await this.$store.commit(
                "UPDATE_AGENT_TICKET_URL_CREATE",
                res.detail.ticket_create_url
              );
              this.items = res.detail.data;
              this.ticketUrlViewAll = res.detail.ticket_url_view_all;
              result = 1;
            }
          })
          .catch(error => {
            this.isLoading = false;
            console.log("error", error);
          });
        this.isLoading = false;
      }
      return result;
    },
    toUrlTicket(url) {
      window.open(`${url}`, "_blank").focus();
    },
    showFormTicket(ticketUrl) {
      this.$refs.ModalTicketIframe.show(ticketUrl);
    }
  }
};
</script>

<style lang="scss" scoped>
.recent-card {
  background-color: white;
  display: flex;
  gap: 8px;
  flex-direction: column;
  border-radius: 0.75rem;

  .activity-card {
    display: flex;
    gap: 8px;

    .activity-icon {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      hr {
        height: 100%;
        border: 0.5px solid #eeeeee;
      }

      .icon {
        color: #832abf;
        cursor: pointer;
        font-size: 24px;
      }
    }

    &.is-close {
      opacity: 0.5;
    }
  }

  .title {
    color: #888888;
  }

  .date {
    font-size: 12px;
    color: #bababa;
    display: flex;
    gap: 8px;
    align-items: center;

    .icon {
      color: #832abf;
    }
  }

  .name {
    font-size: 14px;
  }

  .info {
    font-size: 12px;
    color: #bababa;
    display: flex;
    align-items: center;

    // &ul {
    padding-left: 0;
    margin-bottom: 0;

    li {
      display: inline-flex;
      color: #bababa !important;
      gap: 4px;
      margin-right: 8px;
      align-items: center;
    }

    li::before {
      content: "•";
      padding-right: 8px;
      color: #a2a2a2;
    }
    // }
  }

  .type {
    font-size: 14px;
  }
}

.activity-card-nodata {
  color: #888888;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  border-radius: 10px;
  opacity: 0.5;
}

.edit-icon {
  color: #832abf;
  cursor: pointer;
  font-size: 16px;
}

.badge-new {
  background-color: rgb(39, 189, 167);
  color: white;
  padding: 0.4em 0.6em;
  font-size: 10px;
}
</style>
