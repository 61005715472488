var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100"},[_c('ChatSideBarSetReply'),(_vm.isShowChat && !(_vm.isNoConversation && _vm.conversationId == ''))?_c('div',{staticClass:"d-flex h-100"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activePanel == 1 || _vm.isShowBoth),expression:"activePanel == 1 || isShowBoth"}],class:['panel-list-chat']},[(_vm.isNoConversation)?_c('div',{staticClass:"h-100 border-right"},[_c('div',{staticClass:"panel-wellcome-chat"},[_c('div',{staticClass:"text-center"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'comment-alt']}}),_c('p',{staticClass:"mb-0"},[_vm._v("ไม่พบรายการแชท")])],1)])]):_c('ChatList',{ref:"ChatList",attrs:{"conversationId":_vm.conversationId},on:{"select-chat":_vm.handleSelectChat,"set-show-title":val => _vm.$emit('set-show-title', val),"handle-no-list-chat":val => (_vm.isNoConversation = val),"exit-chat-selected":_vm.exitChatSelected}})],1),(_vm.activePanel == 2 || _vm.activePanel == 3 || _vm.isShowBoth)?_c('div',{class:['panel-box-chat']},[(_vm.activePanel == 2 && _vm.conversationId != '')?_c('ChatBox',{class:[_vm.isShowBoth ? 'w-50' : 'w-100'],attrs:{"meProfile":_vm.meProfile,"otherProfile":_vm.otherProfile,"isFromList":_vm.isFromList,"isShowBoth":_vm.isShowBoth,"socialType":_vm.socialType,"formatIdConversation":_vm.conversationId,"userId":_vm.selectedUserId,"channelId":_vm.selectedChannelId,"conversationInfo":_vm.conversationInfo,"userChatInfo":_vm.selectedUserChatInfo},on:{"show-list-chat":() => {
            _vm.setActivePanel(1);
            _vm.conversationId = '';
          },"set-show-title":val => _vm.$emit('set-show-title', val),"reload-list":function($event){return _vm.$refs.ChatList.getChatList(false)},"reload-detail-list":val => _vm.$refs.ChatList.reloadDetailList(val),"set-read-conversation":val => _vm.$refs.ChatList.setReadConversation(val),"handle-no-list-chat":val => (_vm.isNoConversation = val),"set-selected-user-chat":val => (_vm.selectedUserChatInfo = val)}}):_vm._e(),((_vm.activePanel == 2 || _vm.isShowBoth) && _vm.conversationId == '')?_c('div',{staticClass:"panel-wellcome-chat"},[_c('div',{staticClass:"text-center"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'comment-alt']}}),_c('p',{staticClass:"mb-0"},[_vm._v("ยินดีตอนรับเข้าสู่แชท")])],1)]):_vm._e(),(
          (_vm.activePanel == 3 || _vm.isShowBoth) &&
            _vm.selectedUserChatInfo.externalId != '' &&
            _vm.selectedUserChatInfo.externalId.length > 0
        )?_c('ChatOrderPanel',{staticClass:"w-50 border-left",attrs:{"userChatInfo":_vm.selectedUserChatInfo,"socialType":_vm.socialType,"chatRoomId":_vm.conversationId,"isShowBoth":_vm.isShowBoth},on:{"get-chatlist":function($event){return _vm.$refs.ChatList.reloadDetailList()}}}):_vm._e()],1):_vm._e()]):_vm._e(),(_vm.isNoConversation && _vm.conversationId == '')?_c('div',{staticClass:"h-100"},[_c('div',{staticClass:"panel-wellcome-chat"},[_c('div',{staticClass:"text-center"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'comment-alt']}}),_c('p',{staticClass:"mb-0"},[_vm._v("ไม่พบรายการแชท")])],1)])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }