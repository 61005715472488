<template>
  <div :class="['warp-history', { show: isShow }]" @click="hide">
    <b-sidebar
      id="sidebar-filter-history"
      :backdrop-variant="variant"
      backdrop
      shadow
      visible
      no-header
      right
    >
      <div class="sidepanel" @click.stop="">
        <div
          class="sticky-top d-flex justify-content-between align-items-center px-3"
        >
          <span class="title-side">Chat History</span>
          <a href="javascripxt:void(0)" class="closebtn" @click="hide">×</a>
        </div>
        <div class="filter-form border-top pt-2">
          <div class="px-3">
            <div class="text-right mb-3">
              <b-button
                variant="secondary"
                class="w-auto"
                @click="importFileLine"
              >
                <font-awesome-icon
                  icon="file-upload"
                  color="white"
                  class="bg-icon"
                />
                History
              </b-button>
              <!-- <b-dropdown
                size="md"
                variant="light"
                toggle-class="text-decoration-none"
                no-caret
                right
                text="Import Chat"
                class="dropdown-import-chat"
              >
                <b-dropdown-item href="#" @click="importFileLine"
                  >History LINE</b-dropdown-item
                >
                <b-dropdown-item href="#" @click="importFile"
                  >History All</b-dropdown-item
                >
              </b-dropdown> -->
            </div>
            <b-row no-gutters>
              <b-col cols="6" class="pr-2">
                <b-form-group
                  id="input-group-start"
                  class="filter-date"
                  label="From Date Time"
                  label-for="input-start"
                >
                  <datetime
                    input-id="start-date"
                    placeholder="วัน/เดือน/ปี"
                    v-model="filter.start"
                    format="dd/MM/yyyy HH:mm"
                    class="theme-pink"
                    input-class="form-control"
                    type="datetime"
                  >
                  </datetime>
                </b-form-group>
              </b-col>
              <b-col cols="6" class="pl-2">
                <b-form-group
                  id="input-group-end"
                  class="filter-date"
                  label="End Date Time"
                  label-for="input-end"
                >
                  <datetime
                    input-id="end-date"
                    placeholder="วัน/เดือน/ปี"
                    v-model="filter.end"
                    format="dd/MM/yyyy HH:mm"
                    class="theme-pink"
                    input-class="form-control"
                    type="datetime"
                  >
                  </datetime>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- <b-input-group class="mb-3">
              <b-form-input
                placeholder="Search Chat History"
                v-model="filter.search"
                class="position-relative"
                autocomplete="off"
                disabled
              >
              </b-form-input>
              <span class="icon-search">
                <font-awesome-icon :icon="['fas', 'search']"
              /></span>
            </b-input-group> -->
            <b-button variant="secondary" class="w-100" @click="handleSearch">
              ค้นหา
            </b-button>
          </div>
          <div class="chat-search-result">
            <div class="title">
              <span class="title-side">Chat Search Result</span>
              <!-- <b-button
                class="btn-clear p-0"
                variant="link"
                @click="init"
                >×</b-button
              > -->
            </div>
            <div class="results">
              <div
                v-for="(item, index) in chatList"
                :key="index + 'history'"
                class="msg-row"
              >
                <div class="d-flex justify-content-between">
                  <p class="name">{{ item.senderName }}</p>
                  <ChatDateTime class="time" :date="item.sendTime" />
                </div>
                <div class="message" v-html="item.messageHtml"></div>
              </div>
              <infinite-loading
                v-if="chatList.length > 0"
                spinner="spiral"
                @infinite="scrollMethod"
                ref="infiniteLoadingHistory"
              >
                <div
                  slot="spinner"
                  class="spinner-border text-secondary p-2 mt-2"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <div slot="no-more"></div>
                <div slot="no-results"></div>
              </infinite-loading>
              <div
                class="loading-prepare"
                v-if="isLoading && !isLoadingInfinite"
              >
                <div class="spinner-border text-secondary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div v-if="chatList.length < 1 && !isLoading" class="reload-chat">
                <div class="text-center">
                  <p>ไม่พบรายการแชท</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-sidebar>
    <ModalImportFile ref="modalImportFile" @uploadFile="uploadFile" />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import ModalImportFile from "@/components/chat/ModalImportFile";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ChatDateTime from "@/components/chat/ChatDateTime";
import { Datetime } from "vue-datetime";
import * as moment from "moment/moment";

export default {
  props: {
    chatRoomId: {
      required: true,
      type: String
    },
    userId: {
      required: true,
      type: String
    }
  },
  components: {
    datetime: Datetime,
    ModalImportFile,
    ChatDateTime,
    ModalAlert,
    ModalAlertError
  },
  data() {
    return {
      modalMessage: "",
      variant: "dark",
      isShow: false,
      filter: {
        start: moment()
          .subtract(7, "days")
          .format(),
        end: moment().format(),
        search: "",
        length: 20,
        chatroomId: this.chatRoomId,
        messageId: ""
      },
      chatList: [],
      isLoading: false,
      isLoadingInfinite: false,
      importType: ""
    };
  },
  methods: {
    hide() {
      this.isShow = false;
    },
    show() {
      this.init();
      this.isShow = true;
    },
    importFileLine() {
      this.importType = "line";
      this.$refs.modalImportFile.show();
    },
    importFile() {
      this.importType = "all";
      this.$refs.modalImportFile.show();
    },
    uploadFile(item) {
      this.modalMessage = "";
      this.$store.dispatch("setIsLoading", true);
      let body = {
        chatroomId: this.chatRoomId,
        userId: this.userId,
        file: item.File
      };
      let url = "ChatHistory";
      // if (this.importType == "all") url = "AllChatHistory";
      fetch(`${this.$chatBaseUrl}/message/Import/${url}`, {
        method: "post",
        headers: new Headers(this.$headersChat),
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(async result => {
          this.$store.dispatch("setIsLoading", false);
          this.$refs.modalImportFile.hide();
          if (result.result == 1) {
            this.modalMessage = "สำเร็จ";
            this.$refs.modalAlert.show();
            this.$emit("reloadChat");
            this.hide();
          } else {
            this.modalMessage = result.message;
            this.$refs.modalAlertError.show();
          }
        })
        .catch(error => {
          this.$refs.modalImportFile.hide();
          this.$store.dispatch("setIsLoading", false);
          this.modalMessage = error.message;
          this.$refs.modalAlertError.show();
          console.log("error", error);
          this.isError = true;
        });
    },
    async handleSearch() {
      if (this.$refs.infiniteLoadingHistory) {
        this.$refs.infiniteLoadingHistory.stateChanger.reset();
      }
      this.filter.messageId = "";
      this.chatList = [];
      await this.submitSearch();
    },
    init() {
      this.filter = {
        start: moment()
          .subtract(7, "days")
          .format(),
        end: moment().format(),
        search: "",
        length: 20,
        chatroomId: this.chatRoomId,
        messageId: ""
      };
      this.chatList = [];
    },
    submitSearch(scrollFlag = false, $state = false) {
      this.isLoading = true;
      if (this.chatList.length > 0) {
        this.filter.messageId = this.chatList[
          this.chatList.length - 1
        ].messageId;
      } else {
        this.filter.messageId = "";
      }
      let body = { ...this.filter };
      fetch(`${this.$chatBaseUrl}/message/GetChatHistory`, {
        method: "post",
        headers: new Headers(this.$headersChat),
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(async result => {
          this.isLoading = false;
          this.isLoadingInfinite = false;
          if (result.result == 1) {
            if (scrollFlag) {
              if (result.detail.length == 0) {
                if ($state) $state.complete();
              } else {
                let list = this.handleMarkText(
                  result.detail,
                  this.filter.search
                );
                this.chatList = this.chatList.concat(list);
                if ($state) $state.loaded();
              }
            } else {
              let list = this.handleMarkText(result.detail, this.filter.search);
              this.chatList = this.chatList.concat(list);
              if ($state) $state.loaded();
            }
          } else {
            if ($state) $state.complete();
          }
        })
        .catch(error => {
          console.log("error", error);
          this.isError = true;
          this.isLoading = false;
        });
    },
    handleMarkText(items, search) {
      let list = items.map(item => {
        let message = item.message.split(search);
        let messageHtml = "";
        if (search) {
          message.forEach((item2, index) => {
            messageHtml += `${item2}${
              index < message.length - 1 ? `<mark>${search}</mark>` : ""
            }`;
          });
        } else messageHtml = item.message;
        return {
          ...item,
          messageHtml: messageHtml
        };
      });

      return list;
    },
    scrollMethod($state) {
      setTimeout(async () => {
        if (this.chatList.length > 0) {
          this.filter.messageId = this.chatList[
            this.chatList.length - 1
          ].messageId;
        }
        this.isLoadingInfinite = true;
        this.submitSearch(true, $state);
      }, 500);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep #sidebar-filter-history {
  background-color: rgba(70, 70, 70, 0.5) !important;
}
::v-deep .btn-secondary {
  background-color: #832abf !important;
  border-color: #832abf;
  color: #fff;
}
.warp-history {
  position: fixed;
  top: 0;
  right: 0;
  height: 100dvh;
  z-index: 1040;
  overflow-x: hidden;
  width: 0%;
  .sidepanel {
    position: relative;
    right: -400px;
    width: 400px;
    height: 100dvh;
    background-color: rgb(255, 255, 255);
    color: #707070;
    border-left: 1px solid #b3afaf6e;
    margin-left: auto;
    transition: 0.5s;
    .closebtn {
      padding: 8px 0 8px 32px;
      text-decoration: none;
      color: #707070;
      display: block;
      transition: 0.3s;
      font-size: 23px;
    }
    .btn-clear {
      text-decoration: none;
      color: #707070;
      display: block;
      transition: 0.3s;
      font-size: 23px;
    }
    .title-side {
      font-size: 16px;
    }
    .filter-form {
      min-height: calc(100dvh - 60px);
    }
    .filter-date {
      margin-bottom: 15px;
      label {
        color: #575757;
        font-size: 14px;
        margin-bottom: 2px;
      }
    }
    .icon-search {
      position: absolute;
      top: 7px;
      right: 15px;
      color: #768192;
    }
    .chat-search-result {
      background-color: #f6f6f6;
      margin-top: 1rem;
      .title {
        background-color: #fff;
        display: flex !important;
        display: -ms-flexbox !important;
        justify-content: space-between !important;
        -ms-flex-pack: justify !important;
        align-items: center !important;
        -ms-flex-align: center !important;
        padding: 0 1rem 0.5rem 1rem;
      }
      .results {
        height: calc(100dvh - 334px + 61px);
        overflow: hidden;
        overflow-y: auto;
      }
      .results::-webkit-scrollbar {
        width: 0.35em;
      }

      .results::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(177, 175, 175, 0.3);
      }

      .results::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        outline: 1px solid #c1c1c1;
      }
      .msg-row {
        padding: 0.5rem 1rem;
        .name {
          margin-bottom: 0;
          font-size: 14px;
          color: #000000;
          font-weight: 600;
        }
        .time {
          color: #bfbfbf;
          font-size: 14px;
        }
      }
    }
  }
  .reload-chat,
  .loading-prepare {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}
.warp-history.show {
  width: 100%;
  .sidepanel {
    right: 0;
  }
}
::v-deep mark {
  background-color: #ffc107 !important;
}
::v-deep .form-control:disabled,
.form-control[readonly] {
  background-color: #e9e9e9;
  opacity: 1;
  border: none;
  color: #000;
  overflow-y: hidden !important;
}
::v-deep .dropdown-import-chat {
  ul {
    padding: 0;
  }
  // button {
  //   padding: 0.375rem 0;
  // }
  .dropdown-toggle::after {
    // display: none !important;
    margin-left: 5px;
  }
}
@media (max-width: 767.98px) {
  .warp-history {
    .sidepanel {
      width: 100%;
      right: -100%;
      .chat-search-result {
        .results {
          height: calc(100dvh - 324px + 51px);
        }
      }
    }
  }
  .warp-history.show {
    .sidepanel {
      right: 0;
    }
  }
}
</style>
